import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { B4TextColor, B4TextSmall } from "../text"
import { B4IconButtonNoP } from "../button"
import { MdClear } from "react-icons/md"

export interface B4ControlProps {
  name: string,
  control: Control<any>,
}

export interface B4InputCommonProps {
  label?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean
}

export interface B4Options {
  label: string,
  value: string,
}

export interface B4OptionProps {
  options: B4Options[]
}

export const B4InputExtension = ({children}) => (
  <div className="pt-1 pl-[0.563rem]">{children}</div>
)

export const genCommonInputStyling = (diabled, error) => [
  'border-b4-primary border-solid border bg-white p-2 rounded',
  {
    'hover:p-[7px] hover:border-2': !diabled,
    'bg-gray-100': diabled,
    'border-red-500': !!error,
  }
]

export const useB4Validations = () => {
  const { t } = useTranslation()
  return {
    patterns: {
      email: {
        value: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
        message: t('txtValidationEMail')
      },
      password: {
        value: /^.{8,}$/,
        message: t('txtValidationMinLength', { minLength: 8 }),
      },
    },
    messages: {
      required: t('txtValidationRequired'),
    }
  }
}





export const B4InputError = ({error}) => (
  <>{ error?.message && <B4InputExtension>
    <B4TextSmall color={B4TextColor.RED}>{error.message}</B4TextSmall>
  </B4InputExtension> }</>
)

export const B4Label = ({label, required}) => label ? (
  <div className="pb-1">
    <B4TextSmall>{`${label}`}:</B4TextSmall>
  </div>
) : null

export const B4InputIconButton = ({onClick, children}) => (
  <B4IconButtonNoP className="p-0.5" onClick={onClick}>{children}</B4IconButtonNoP>
)

export const B4InputClear = ({onClick}) => (
  <B4InputIconButton onClick={e => {
    onClick()
    e.stopPropagation()
  }}>
    <MdClear fontSize="inherit"/>
  </B4InputIconButton>
)